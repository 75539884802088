// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper, { EffectFade, Navigation } from "swiper";

const HERO = document.querySelector(".swiper-container--hero");

// init swiper
if (HERO && HERO.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper (HERO, {
        autoplay: {
            delay: 15000,
        },
        effect: "fade",
        loop: true,
        modules: [EffectFade, Navigation],
        navigation: {
            nextEl: HERO.querySelector(".swiper-button--next"),
            prevEl: HERO.querySelector(".swiper-button--prev"),
        },
        speed: 150,
    });
}
